<template>
  <v-container fluid>
    <div>
      <v-row>
        <v-col
          cols="6"
          md="3"
          class="d-flex align-center justify-center avatar"
        >
          <div class="">
            <div v-if="Student.avatar">
              <v-avatar size="20rem">
                <v-img :src="`${Student.avatar}`" class="rounded">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </div>
            <v-icon color="black" size="20rem" v-else class="avatar-svg"
              >mdi-account-tie</v-icon
            >
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <p class="mb-2 text-justify">
            <span class="bold">Nom :</span>
            <span class="ml-1">{{ Student.lastName }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Prénoms :</span>
            <span class="ml-1">{{ Student.firstName }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Date d'anniversaire:</span>
            <span class="ml-1">{{ Student.birthday }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Lieu de naissance :</span>
            <span class="ml-1">{{ Student.birthPlace }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Nationalité :</span>
            <span class="ml-1">{{ Student.nationality }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Pays :</span>
            <span class="ml-1">{{ Student.country }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Ville :</span>
            <span class="ml-1">{{ Student.city }}</span>
          </p>

          <p class="mb-2 text-justify">
            <span class="bold">Téléphone :</span>
            <span class="ml-1">{{ Student.phone }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Adress:</span>
            <span class="ml-1">{{ Student.address }}</span>
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <p class="mb-1">
              <span>Option :</span>
              <span class="bold">{{ Student.department.name }}</span>
            </p>
            <p class="mb-1">
              <span>Niveau :</span>
              <span class="bold">{{ Student.translatedLevel }}</span>
            </p>
          </div>
          <div class="mt-4">
            <p v-if="Student.godfather" class="mb-1">
              <span>Parain :</span>
              <span class="bold">{{ Student.godfather.fullname }}</span>
            </p>
          </div>
        </v-col>
      </v-row>

      <div class="my-5 container">
        <div class="py-5 mt-5 mb-12">
          <h2 class="primary--text mb-10">Dettes standard</h2>
          <v-row>
            <v-col cols="12" class="text-center" md="3">
              <h4 class="border-red rounded-pill py-2">
                Montant à payer :
                {{ Student.totalAmountStandardDebtsFormat }} FCFA
              </h4>
            </v-col>
            <v-col cols="12" class="text-center" md="3">
              <h4 class="border-green rounded-pill py-2">
                Montant payer : {{ Student.totalStandardPaidFormat }} FCFA
              </h4>
            </v-col>
            <v-col cols="12" class="text-center" md="3">
              <h4 class="border-orange rounded-pill py-2">
                Reste à payer : {{ Student.totalLeftStandardDebtsFormat }} FCFA
              </h4>
            </v-col>
            <v-col cols="12" class="text-center" md="2">
              <h4 class="border-orange rounded-pill py-2">
                Reduction : {{ Student.totalReduction }} FCFA
              </h4>
            </v-col>
            
          </v-row>
        </div>
        <v-row>
          <v-col
            class=""
            v-for="(item, i) of Student.standardDebts"
            :key="i"
            cols="12"
            md="4"
          >
            <div class="rounded elevation-1 py-4 px-2">
              <h3>{{ item.standardFees.name }}</h3>
              <p class="mb-1">
                <span>Montant :</span>
                <span class="primary--text"> {{ item.amountFormat }} FCFA</span>
              </p>
              <p>
                <span>Reste :</span>
                <span class="primary--text">{{ item.leftFormat }} FCFA</span>
              </p>
              <p v-if="item.percentageReduction > 0">
                <span>Reduction :</span>
                <span class="primary--text"
                  >{{ item.percentageReduction * 100}} %</span
                >
              </p>

              <div>
                <div>
                  <h4>Détails des paiements :</h4>
                  <ul v-if="item.payments && item.payments.length > 0">
                    <li v-for="(payment, i) of item.payments" :key="i">
                      <div class="px-3">
                        <div class="d-flex justify-space-between">
                          <p class="text-subtitle-2 mb-0">
                            <span class="">Montant : </span>
                            <span class="text-caption"
                              >{{ payment.amount }} FCFA</span
                            >
                          </p>
                          <p class="text-subtitle-2 mb-0">
                            <span class="">Date : </span>
                            <span class="text-caption">{{
                              payment.createdAtTime
                            }}</span>
                          </p>
                        </div>
                        <div class="d-flex justify-space-between">
                          <p
                            v-if="payment.createdBy"
                            class="text-subtitle-2 mb-0"
                          >
                            <span class="">Enregistrer par : </span>
                            <span class="text-caption"
                              >{{ payment.createdBy.lastName }}
                              {{ payment.createdBy.firstName }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <p v-else class="mb-0 ml-3 text-subtitle-2">
                    Pas de paiement
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="my-5 container">
        <div class="py-5 mt-5 mb-12">
          <v-row>
            <v-col cols="12" md="3">
              <h2 class="primary--text">Dettes additionels</h2>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col
            class=""
            v-for="(item, i) of Student.additionalDebts"
            :key="i"
            cols="12"
            md="4"
          >
            <div class="rounded elevation-1 py-4 px-2">
              <h3>{{ item.additionalCoast.name }}</h3>
              <p class="mb-1">
                <span>Montant :</span>
                <span class="primary--text"> {{ item.amountFormat }} FCFA</span>
              </p>
              <p>
                <span>Reste :</span>
                <span class="primary--text">{{ item.leftFormat }} FCFA</span>
              </p>

              <div>
                <div>
                  <div class="d-flex justify-space-between">
                    <h4 class="mb-5">Détails des paiements</h4>
                   
                  </div>
                  <ul v-if="item.payments && item.payments.length > 0">
                    <li v-for="(payment, i) of item.payments" :key="i">
                      <div class="px-3">
                        <div class="d-flex justify-space-between">
                          <p class="text-subtitle-2 mb-0">
                            <span class="">Montant : </span>
                            <span class="text-caption"
                              >{{ payment.amount }} FCFA</span
                            >
                          </p>
                          <p class="text-subtitle-2 mb-0">
                            <span class="">Date : </span>
                            <span class="text-caption">{{
                              payment.createdAtTime
                            }}</span>
                          </p>
                        </div>
                        <div class="d-flex justify-space-between">
                          <p
                            v-if="payment.createdBy"
                            class="text-subtitle-2 mb-0"
                          >
                            <span class="">Enregistrer par : </span>
                            <span class="text-caption"
                              >{{ payment.createdBy.lastName }}
                              {{ payment.createdBy.firstName }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <p v-else class="mb-0 ml-3 text-subtitle-2">
                    Pas de paiement
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="my-5 container">
        <div class="mt-5">
          <h2>Operations</h2>

          <v-row class="mt-5">
            <v-col v-for="(item, i) of Operations" :key="i" cols="12" md="4">
              <div class="rounded elevation-1 py-4 px-2">
                <h3>
                  Payement pour :
                  <span class="primary--text">{{ item.object }}</span>
                </h3>
                <p class="mb-1">
                  <span>Montant :</span>
                  <span class="primary--text"> {{ item.amount }} FCFA</span>
                </p>

                <p class="mb-1">
                  <span>Date :</span>
                  <span class="primary--text"> {{ item.createdAtTime }} </span>
                </p>

                <div>
                  <h3>Reçu</h3>
                  <div class="ml-5">
                    <p class="mb-1">
                      <span>N° :</span>
                      <span class="primary--text">
                        {{ item.receiptCode }}
                      </span>
                    </p>
                    <p class="mb-1">
                      <span>Déjà imprimer :</span>
                      <span class="primary--text">
                        {{ item.receiptIsPrint ? "Oui" : "Non" }}
                      </span>
                    </p>
                    <v-btn
                      @click="printReceipt(item._id)"
                      :loading="loading"
                      class="orange white--text mt-5 d-none"
                      >Imprimer le reçu</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="standardFeesEditorDialog"
      max-width="1000px"
      class="black"
    >
      <editor-x
        :fields="standardFeesfields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "@/utils/data";
import {
  paidStandardDept,
  paidAdditionalDept,
  deleteFaculty,
  printOperationReceipt,
} from "@/api/user";
import { rules, functions } from "@/utils/constant.js";
import EditorX from "@/components/universal/EditorX.vue";
import DeleteDialog from "@/components/universal/DeleteDialog.vue";

export default {
  name: "Student",
  components: {
    EditorX,
    DeleteDialog,
  },
  data: () => ({
    callBack: "",
    searchData: "",
    dialog: false,
    loading: false,
    headers: [
      {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
  sortable: true,
      },
      {
        text: "Numéro matricule",
        align: "start",
        sortable: true,
        value: "registrationNumber",
      },
      {
        text: "Nom",
        align: "start",
        sortable: true,
        value: "lastName",
      },
      {
        text: "Prénoms",
        align: "start",
        sortable: true,
        value: "firstName",
      },
      {
        text: "Options",
        align: "start",
        sortable: true,
        value: "departmentName",
      },
      {
        text: "Niveau",
        align: "start",
        sortable: true,
        value: "translatedLevel",
      },
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    standardFeesEditorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(["Student", "Schools", "UserProfile", "Operations"]),
    standardFeesfields() {
      return [
        {
          name: "amount",
          label: "Montant",
          type: "Number",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
            rules.max(
              this.Student.totalLeftStandardDebts,
              `Les frais standard restant de l'étudiant s'élève à ${this.Student.totalLeftStandardDebts} FCFA`
            ),
          ],
        },
        {
          name: "student",
          label: "Montant",
          type: "Static",
          value: this.$route.params.id,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
  },

  watch: {},
  methods: {
    ...mapActions(["getStudent", "getUserProfile", "getOperations"]),
    closeForm() {
      this.Model = {};
      this.standardFeesEditorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getStudent({
          id: this.$route.params.id,
        });
        await this.getUserProfile();
        await this.getOperations({
          student: this.$route.params.id,
        });
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les données")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.standardFeesEditorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    paidStandardDebts() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = paidStandardDept;
      this.standardFeesEditorDialog = true;
    },
    paidAdditionalCoast(item) {
      this.editedIndex = -1;
      this.Model = {
        _id: item._id,
      };
      this.callBack = paidAdditionalDept;
      this.standardFeesEditorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.standardFeesEditorDialog = true;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteFaculty;
    },
    async printReceipt(id) {
      this.loading = true;
      try {
        let url = await printOperationReceipt({
          schema: {
            id: id,
          },
        });

        await functions.downloadFromLink(url);
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
      this.init();
    },
  },
};
</script>

<style >
</style>
